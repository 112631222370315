@import "colors.scss";

.time-picker-holder {
    position: relative;

    .time-picker-input {
        display: table;
        width: 100%;

        > div {
            display: table-row;

            > input {
                display: table-cell;
                width: 100%;
                height: 36px;
                vertical-align: middle;
            }

            > span.add-on {
                display: table-cell;
                width: 40px;
                border: none;
                padding: 4px;

                > .btn {
                    border: none; padding: 0;
                }
            }
        }
    }

    .time-picker-child {
        .time-picker {
            background-color: $white;
            z-index: 100;
            position: absolute;
        }
    }
}

.time-picker {
    display: inline-block;
    border: 1px solid $default-border;
    border-radius: 4px;
    padding: 8px;

    tr {
        td {
            width: 64px;
            text-align: center;
        }

        td:nth-child(2) {
            width: 18px;
        }
    }

    .tp-button-row {
        button {
            background-color: white;
            border: none;

            width: 100%;
            height: 36px;

            border-radis: 4px;

            i {
                vertical-align:middle;
                margin-bottom:6px;
            }
        }

        button:hover {
            background-color: #efefef;
        }
    }

    .tp-input-row {
        input {
            box-sizing: border-box;
            border: 1px solid $default-border;
            width: 100%;

            font-size: 20px;
            text-align: center;
        }
    }
}